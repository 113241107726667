import React, { useState } from 'react';
import { styled, theme } from '../../Theme';
import { media } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';
import useMedia from '../../Shared/Hooks/useMedia';

import IImageModel from '../../Assets/Models/ImageModel.interface';
import ILink from '../Models/Link.interface';

type HeroModuleType = {
  image: IImageModel;
  inEditMode: boolean;
  header?: string;
  summary?: string;
  links?: ILink[];
  blurText: boolean;
};

function HeroModule({ inEditMode, image, blurText }: HeroModuleType) {
  const imageContainerStyle: StyleOrStyleArray = {
    backgroundImage: `url(${image.src})`,
  };

  const {
    translations: { 'productPage/showMore': showMore },
  } = useAppSettingsData();

  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);
  const [showMoreText, setShowMore] = useState(false);

  return <ImageContainer css={imageContainerStyle}></ImageContainer>;
}

const ImageContainer = styled.img({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '345px',
  height: '195px',
  margin: { y: theme.theta, x: 'auto' },
  ...media(
    theme.mediaQuery.mediaMinLarge,
    /*Desktop*/ {
      margin: { y: theme.heroImageYMargin },
      width: '654px',
      height: '505px',
    }
  ),
  ...media(theme.mediaQuery.mediaMinMedium, {
    maxWidth: theme.maxWidthSmall,
    margin: { y: theme.headingDesktop },
    width: '584px',
  }),
});

export default HeroModule;
