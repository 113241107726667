import React from 'react';
import NotFoundPageModel from './Models/NotFoundPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';

import HeroModule from '../Shared/Hero/HeroModule';
import ContentArea from '../Shared/ContentArea/ContentArea';
import Grid from '../Shared/Grid/Grid';
import { applyEditModeAttr } from '../Shared/Common/Helpers';

function NotFoundPage() {
  const {
    contentArea,
    inEditMode,
    teaserHeader,
    teaserImage,
    teaserLinks,
    teaserText,
    blurTeaserText,
  } = useCurrentPage<NotFoundPageModel>();

  return (
    <>
      {teaserImage && (
        <HeroModule
          header={teaserHeader}
          summary={teaserText}
          inEditMode={inEditMode}
          links={teaserLinks}
          image={teaserImage}
          blurText={blurTeaserText}
        />
      )}
      <Grid
        type={12}
        data-content-area
        {...applyEditModeAttr(inEditMode && 'MainContent')}
      >
        <ContentArea childItems={contentArea} />
      </Grid>
    </>
  );
}

export default NotFoundPage;
